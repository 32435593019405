import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO/index'
import NewsContent from 'components/News'

const News = ({ data, pageContext, location }) => {
  const {
    allMarkdownRemark: { edges }
  } = data

  return (
    <>
      <SEO title="News" />
      <NewsContent data={edges} location={location.pathname} />
    </>
  )
}

export default News

export const query = graphql`
  query($news: Boolean) {
    allMarkdownRemark(
      filter: { frontmatter: { news: { eq: $news } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
