import React from 'react'
import { Link } from 'gatsby'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'
import { formatDate } from 'utils'

import Intro from 'elements/Intro'

const NewsContent = ({ data, location }) => {
  const {
    newsIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="News" imgSrc={src} />
        {data.length &&
          data.map(
            ({
              node: {
                excerpt,
                id,
                fields: { slug },
                frontmatter: { title, date }
              }
            }) => {
              return (
                <div key={`${id}-${slug}`}>
                  <div className="mt-14 mb-14 max-w-900px">
                    <h2 className="text-3xl md:text-4xl mb-1 font-bold">{title}</h2>
                    <span className="text-sm text-vividBlue">{formatDate(date, 'en-us')}</span>
                    <p className="text-sm my-5">{excerpt}</p>

                    <Link
                      className="inline-flex self-start text-white bg-vividBlue text-sm px-5 py-3 rounded-3xl transform transition duration-300 ease-in-out hover:-translate-y-1"
                      to={`${location}/${slug}`}
                    >
                      Read More
                    </Link>
                  </div>
                  <hr style={{ height: '2px' }} className="my-5 bg-vividBlue opacity-50" />
                </div>
              )
            }
          )}
      </div>
    </section>
  )
}

export default NewsContent
